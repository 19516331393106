import { getHomeSection as homeSection } from "~/api/home_section/getHomeSection";
import type { Branch } from "~/types/Branch";
import type { RestaurantTags, FeaturedRestaurant } from "~/types/Restaurant";

export function isRestaurantTags(
  sectionData: FeaturedRestaurant | RestaurantTags | Branch
): sectionData is RestaurantTags {
  return (sectionData as RestaurantTags).type === "restaurant_tags";
}

export function isBranchesTags(
  sectionData: FeaturedRestaurant | RestaurantTags | Branch
): sectionData is Branch {
  return (sectionData as Branch).type === "branches";
}

export function isFeaturedRestaurant(
  sectionData: FeaturedRestaurant | RestaurantTags | Branch
): sectionData is FeaturedRestaurant {
  return sectionData.type === "featured_restaurants";
}

async function getHomeSection({
  pageNumber,
  pageSize,
  order,
  cityId,
  endPointApi,
}: {
  pageNumber?: number;
  pageSize?: number;
  order?: number;
  cityId?: number | string;
  endPointApi?: string;
}) {
  const { isSuccess, data, message } = await homeSection({
    pageNumber,
    pageSize,
    endPointApi,
    order,
    cityId,
  });
  if (!isSuccess || !data) {
    throw new Error(message);
  }

  const remapData = data.data.map((sectionData) => {
    if (isBranchesTags(sectionData)) {
      return sectionData;
    }
    if (!isRestaurantTags(sectionData)) {
      return sectionData;
    }
    return sectionData;
  });
  return {
    isSuccess,
    message,
    data: {
      ...data,
      data: remapData,
    },
  };
}

export { getHomeSection };
